import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Modal,
  Box,
  IconButton,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Snackbar,
  FormHelperText,
  Alert as MuiAlert,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Close as CloseIcon } from '@mui/icons-material';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import CSVFileViewer from './CSVFileViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>File Logs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

const FILE_TYPE = {
  ALL: 'ALL',
  OFFER_FILE: 'OFFER_FILE',
  RECURRING_PAYMENTS_FILE: 'RECURRING_PAYMENTS_FILE',
  PAYMENT_RESPONSE: 'PAYMENT_RESPONSE',
  OFFER_REMINDER: 'OFFER_REMINDER',
  PAYMENT_ACKNOWLEDGEMENT: 'PAYMENT_ACKNOWLEDGEMENT',
  GENERATED_OFFERS: 'GENERATED_OFFERS',
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function SubscriptionsFileLogs({ viewHeight = '30vh' }) {
  const [tableColumns, setTableColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offerLimit, setOfferLimit] = useState('');
  const [filterClient, setFilterClient] = useState('');
  const [filterExpiryDate, setFilterExpiryDate] = useState(null);
  const [isLoading, setIsLoading] = useState('false');
  const [filterFileType, setFilterFileType] = useState(FILE_TYPE.ALL);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [isEditing, setIsEditing] = useState(false);
  const handleClose = () => setOpen(false);
  const [apiResponse, setApiResponse] = useState({
    toastOpen: false,
    message: '',
    success: false,
  });
  const [errors, setErrors] = useState({
    offerLimit: false,
    filterExpiryDate: false,
    filterClient: false,
  });
  const dispatch = useDispatch();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setApiResponse({
      toastOpen: false,
      message: '',
      success: false,
    });
  };

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async () => {
    let filterValue = {};
    if (filterFileType !== FILE_TYPE.ALL) {
      filterValue = { file_type: { eq: filterFileType } };
    }
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterValue),
    };
    setIsLoading(true);

    return subscriptionsApi.getFileLogs(dispatch, params);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const validateForm = () => {
    const newErrors = {
      offerLimit:
        offerLimit === '' || Number.isNaN(offerLimit) || offerLimit <= 0,
      filterExpiryDate: !filterExpiryDate,
      filterClient: !filterClient,
    };
    setErrors(newErrors);
    // If any field has an error, return false to prevent submission
    return !Object.values(newErrors).some((error) => error);
  };

  function updateColumns() {
    const columns = [];
    columns.push({
      headerName: 'Date',
      renderCell: (c) =>
        moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
      field: 'createdAt',
      width: 160,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'File Name',
      render: (e) => e.fileName,
      field: 'fileName',
      headerClassName: 'custom-dark-theme--header',
      width: 360,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'File Type',
      render: (e) => e.fileType,
      field: 'fileType',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Status',
      render: (e) => e.processStatus,
      field: 'processStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Total',
      renderCell: (c) => `${c.value !== null ? c.value : ''}`,
      field: 'totalNoOfRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Valid',
      rrenderCell: (c) => `${c.value !== null ? c.value : ''}`,
      field: 'totalValidRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'Failed',
      renderCell: (c) => `${c.value !== null ? c.value : ''}`,
      field: 'totalFailedRecords',
      headerClassName: 'custom-dark-theme--header',
      width: 70,
      disableColumnMenu: true,
    });
    columns.push({
      headerName: 'File Path',
      renderCell: (params) => {
        return (
          <div>
            <CSVFileViewer
              title={params.row.fileName}
              path={params.row.filePath}
            />
          </div>
        );
      },
      field: 'filePath',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
      disableColumnMenu: true,
    });
    if (
      filterFileType === FILE_TYPE.ALL ||
      filterFileType === FILE_TYPE.OFFER_FILE
    ) {
      columns.push({
        headerName: 'Notified',
        render: (e) => e.notificationSent,
        field: 'notificationSent',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
      columns.push({
        headerName: 'Failed File Path',
        renderCell: (params) => {
          return (
            <div>
              <CSVFileViewer
                title={params.row.failedPath}
                path={params.row.failedPath}
              />
            </div>
          );
        },
        field: 'failedPath',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
    }
    if (filterFileType !== FILE_TYPE.OFFER_FILE) {
      columns.push({
        headerName: 'Response File Path',
        renderCell: (params) => {
          return (
            <div>
              <CSVFileViewer
                title={params.row.responseFilePath}
                path={params.row.responseFilePath}
              />
            </div>
          );
        },
        field: 'responseFilePath',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
    }
    if (
      !isUK &&
      (filterFileType === FILE_TYPE.ALL ||
        filterFileType === FILE_TYPE.OFFER_FILE)
    ) {
      columns.push({
        headerName: 'SFMC File Path',
        renderCell: (params) => {
          return (
            <div>
              <CSVFileViewer
                title={params.row.sfmcFilePath}
                path={params.row.sfmcFilePath}
              />
            </div>
          );
        },
        field: 'sfmcFilePath',
        headerClassName: 'custom-dark-theme--header',
        width: 120,
        disableColumnMenu: true,
      });
    }
    columns.push({
      headerName: 'Metadata',
      render: (e) => e.metaData,
      field: 'metaData',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
      disableColumnMenu: true,
    });
    setTableColumns(columns);
  }

  function selectTab(type) {
    setDataSource([]);
    setRowCount(0);
    setFilterFileType(type);
  }

  function getTabVariant(type) {
    if (filterFileType === type) {
      return 'contained';
    }
    return 'outlined';
  }

  const handleOfferLimit = ({ target: { value } }) => {
    // Ensure the value is an integer and non-negative
    const numericValue =
      value === '' ? '' : Math.floor(Math.abs(Number(value)));
    setOfferLimit(numericValue);
  };

  const handleFilterClient = (event) => {
    setFilterClient(event.target.value);
  };

  useEffect(() => {
    const loadData = async () => {
      updateColumns();
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data.data);
        setRowCount(reports.data.totalItems);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filterFileType]);

  const handleGenerateOffer = async () => {
    if (!validateForm()) {
      return; // Do not proceed if the form is invalid
    }
    const offerData = {
      offerLimit,
      clientType: filterClient,
      offerExpiryDate: filterExpiryDate,
      scheduledJobType: 'GENERATE_OFFERS',
    };
    console.log('offerData->>>', offerData);
    setIsEditing(true);
    // Make API call to generateOffer
    const response = await subscriptionsApi.generateOffer(dispatch, offerData);
    setIsEditing(false);
    const message =
      response && response.success
        ? 'Offer Generated Successfully'
        : 'Offer Generate Failed';
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
    // Close the edit modal
    window.location.reload();
  };

  return (
    <div>
      <div style={{ marginBottom: 5 }} className="d-flex">
        <div>
          <ButtonGroup disableElevation variant="outlined" color="primary">
            <Button
              variant={getTabVariant(FILE_TYPE.ALL)}
              onClick={() => selectTab(FILE_TYPE.ALL)}
            >
              ALL
            </Button>
            <Button
              variant={getTabVariant(FILE_TYPE.OFFER_FILE)}
              onClick={() => selectTab(FILE_TYPE.OFFER_FILE)}
            >
              OFFERS
            </Button>
            <Button
              variant={getTabVariant(FILE_TYPE.RECURRING_PAYMENTS_FILE)}
              onClick={() => selectTab(FILE_TYPE.RECURRING_PAYMENTS_FILE)}
            >
              RECURRING
            </Button>
            {!isUK && (
              <Button
                variant={getTabVariant(FILE_TYPE.PAYMENT_RESPONSE)}
                onClick={() => selectTab(FILE_TYPE.PAYMENT_RESPONSE)}
              >
                PAYMENT RESPONSE
              </Button>
            )}
            <Button
              variant={getTabVariant(FILE_TYPE.OFFER_REMINDER)}
              onClick={() => selectTab(FILE_TYPE.OFFER_REMINDER)}
            >
              REMINDERS
            </Button>
            {!isUK && (
              <Button
                variant={getTabVariant(FILE_TYPE.PAYMENT_ACKNOWLEDGEMENT)}
                onClick={() => selectTab(FILE_TYPE.PAYMENT_ACKNOWLEDGEMENT)}
              >
                PAYMENT ACKNOWLEDGEMENT
              </Button>
            )}
            {!isUK && (
              <Button
                variant={getTabVariant(FILE_TYPE.GENERATED_OFFERS)}
                onClick={() => selectTab(FILE_TYPE.GENERATED_OFFERS)}
              >
                GENERATED OFFERS
              </Button>
            )}
          </ButtonGroup>
        </div>
        <div className="flex-grow-1" />
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Generate Offer
        </Button>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 450, padding: '20px' }}>
          {/* Header Section */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <h2>Schedule Offer</h2>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>

          {/* Offer Limit Input */}
          <TextField
            label="Offer Limit"
            variant="outlined"
            type="number"
            value={offerLimit}
            onChange={handleOfferLimit}
            fullWidth
            inputProps={{
              min: 1, // Optional: Minimum value if needed
              step: 1, // Only allow whole numbers
            }}
            error={errors.offerLimit}
            helperText={
              errors.offerLimit ? 'Please enter a valid Offer Limit' : ''
            }
            style={{ marginBottom: '16px' }}
          />

          {/* Expiry Date Picker */}
          <FormControl
            style={{ marginBottom: '16px', width: '100%' }}
            error={errors.filterExpiryDate}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Expiry Date"
                variant="inline"
                fullWidth
                id="expiry-date-filter"
                value={filterExpiryDate}
                onChange={setFilterExpiryDate}
                autoOk
              />
            </LocalizationProvider>
            {errors.filterExpiryDate && (
              <FormHelperText>Please select an expiry date</FormHelperText>
            )}
          </FormControl>

          {/* Client Select Input */}
          <FormControl
            style={{ marginBottom: '16px', width: '100%' }}
            error={errors.filterClient}
          >
            <InputLabel htmlFor="select-client-label">Client</InputLabel>
            <Select
              labelId="select-client-label"
              id="select-client"
              value={filterClient}
              onChange={handleFilterClient}
              fullWidth
              style={{ overflowY: 'auto' }}
            >
              <MenuItem key="SUBSCRIPTION" value="SUBSCRIPTION">
                SUBSCRIPTION
              </MenuItem>
              <MenuItem key="GEICO" value="GEICO">
                GEICO
              </MenuItem>
              <MenuItem key="RIVIAN" value="RIVIAN">
                RIVIAN
              </MenuItem>
            </Select>
            {errors.filterClient && (
              <FormHelperText>Please select a client</FormHelperText>
            )}
          </FormControl>

          {/* Save Changes Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateOffer}
            fullWidth
            style={{ marginTop: '20px' }}
            disabled={isEditing}
          >
            {isEditing ? (
              <>
                <span>Please Wait...</span>
                <Spinner
                  color="dark"
                  style={{ height: '25px', width: '25px', marginLeft: '10px' }}
                />
              </>
            ) : (
              'Generate Offer'
            )}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={apiResponse.toastOpen}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert
            onClose={handleToastClose}
            severity={apiResponse.success ? 'success' : 'error'}
          >
            {apiResponse.message}
          </Alert>
        </div>
      </Snackbar>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={tableColumns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SubscriptionsFileLogs;
