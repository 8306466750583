import api from '.';

const OFFER_URL = `/admin`;

const getOffers = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/offers`,
    null,
    params
  );
};

const getOfferById = async (dispatch, id) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/offers/${id}`
  );
};

const getOfferQuotes = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/offer-quotes`,
    null,
    params
  );
};

const getTransactionEvents = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/transactions/events`,
    null,
    params
  );
};

const getPaymentResponses = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/transactions/payment-responses`,
    null,
    params
  );
};

const updateOfferById = async (dispatch, id, data) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'put',
    `${OFFER_URL}/offers/${id}`,
    data
  );
};

const resendInvitaionOfferEmail = async (dispatch, id) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/resend-offer/${id}`
  );
};

const deleteOffer = async (dispatch, id) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'delete',
    `${OFFER_URL}/offers/${id}`
  );
};

const getApiErrors = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/api-errors`,
    null,
    params
  );
};

const postQueryRun = async (dispatch, data) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'post',
    `${OFFER_URL}/query/run`,
    data
  );
};

const getFileLogs = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/files`,
    null,
    params
  );
};

const getOfferStatsByDealer = async (dispatch, id) => {
  const endpoint = id
    ? `${OFFER_URL}/offers/by-dealers/${id}`
    : `${OFFER_URL}/offers/by-dealers`;
  return api.pocketDriveSubscriptionsApi(dispatch, 'get', endpoint);
};

const getOfferDealers = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/offers/dealers`,
    null,
    params
  );
};

const getFailedContractCreationsOffers = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/reports/failed-contract-creations`,
    null,
    params
  );
};

const getInvalidVinErrorOffers = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/reports/invalid-vin-errors`,
    null,
    params
  );
};

const getMaxRetryContractFailureOffers = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/reports/max-contract-retry-failures`,
    null,
    params
  );
};

const getOffersLogs = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/offers-log`,
    null,
    params
  );
};

const getRecurringJourneyLogs = async (dispatch, params) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/recurring-journey-log`,
    null,
    params
  );
};

const clearCache = async (dispatch, data) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'post',
    `${OFFER_URL}/system/cache/flush`,
    data
  );
};

const getSubscriptionApiStatus = async (dispatch) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/system/status/health`
  );
};

const getSubscriptionConfig = async (dispatch) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'get',
    `${OFFER_URL}/app-config`
  );
};

const generateOffer = async (dispatch, data) => {
  return api.pocketDriveSubscriptionsApi(
    dispatch,
    'post',
    `${OFFER_URL}/scheduled-jobs`,
    data
  );
};

export default {
  getOffers,
  getOfferById,
  getOfferQuotes,
  getTransactionEvents,
  getPaymentResponses,
  updateOfferById,
  resendInvitaionOfferEmail,
  deleteOffer,
  getApiErrors,
  postQueryRun,
  getFileLogs,
  getOfferStatsByDealer,
  getOfferDealers,
  getFailedContractCreationsOffers,
  getInvalidVinErrorOffers,
  getMaxRetryContractFailureOffers,
  getOffersLogs,
  getRecurringJourneyLogs,
  clearCache,
  getSubscriptionApiStatus,
  getSubscriptionConfig,
  generateOffer,
};
